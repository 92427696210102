import Modal from '@trendmicro/react-modal';
import * as React from 'react';
import {
  Dropdown,
  Grid,
  Header,
  Segment,
  Button
} from 'semantic-ui-react';
import './_ReseedPlan.scss';
import { AppState, AppThunkDispatch } from 'store';
import { SCOPETYPE_ACTUALS, SCOPETYPE_PLAN, TIME } from 'utils/domain/constants';
import { connect } from 'react-redux';
import { SeedPlan, SeedActuals } from 'state/scope/ScopeManagement.slice';
import { isReady } from 'state/scope/Scope.types';
import { uniqBy, groupBy, isEmpty, Dictionary, startCase, head, last, mapValues } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlanId } from 'state/scope/codecs/PlanMetadata';
import {
  StringStrictDropdownItemProps,
  useHandleKeyPress
} from 'utils/component/hooks/hooks';
import AnchorRadioSelect from 'components/AnchorRadioSelect/AnchorRadioSelect';
import { TopMembers } from 'services/Scope.client';
import { getLastTimeMember, planFromSpace, findEarliestPlan, getScopeObject } from 'components/Scopebar/ScopeUtils';
import { seedAvailableScope } from 'state/scope/scope.actions';
import { memberToDropdown } from 'components/Scopebar/Scopebar.container';
import { toast } from 'react-toastify';

const mapStateToProps = (state: AppState) => {
  const { scope, settings } = state;
  const labelDimenion = settings.dimensionLabelProperty;

  if (!isReady(scope)) {
    return {
      seedOptions: undefined
    };
  }

  const availableMembers = state.viewConfigSlice.availableMembers;
  const timeMembers = availableMembers ? availableMembers.space[TIME].map(memberToDropdown) : [];
  const earliestPlan = findEarliestPlan([...scope.mainConfig.initializedPlans, ...scope.mainConfig.uninitializedPlans]);

  let beforeTime: string | undefined = undefined;
  if (timeMembers && !isEmpty(timeMembers) && earliestPlan) {
    beforeTime = getLastTimeMember(timeMembers, earliestPlan.space.time)[0].value as string;
  }
  const anchor = getScopeObject(scope.mainConfig.memberTrees);
  return {
    scope,
    labelDimenion,
    initializedPlans: scope.mainConfig.initializedPlans,
    seedOptions: scope.seedOptions,
    balanceTimeOptions: getLastTimeMember(timeMembers, earliestPlan.space.time),
    balanceTime: beforeTime,
    eopOptions: scope.eopOptions[Number(earliestPlan.id)],
    anchor
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    seedAvailableScope: (seed: SeedActuals | SeedPlan) => dispatch(seedAvailableScope(seed))
  };
};
export type ReseedPlanModalProps = ReseedPlanModalValueProps & ReseedPlanModalDispatchProps & ReseedPlanModalOwnProps;
export type ReseedPlanModalValueProps = ReturnType<typeof mapStateToProps>;
export type ReseedPlanModalDispatchProps = ReturnType<typeof mapDispatchToProps>;
export type ReseedPlanModalOwnProps = {
  loading: boolean,
  onSubmit: (id?: number) => void,
  onCancel: () => void,
  onOpen: () => void
}

// these are not actaully sorted correctly, so this might not actually be the correct choice
// TODO: get the default options in the following perference RP > OP > actuals
export const getDefaultOptionFromTime = (time: string, seeds: Dictionary<(SeedPlan | SeedActuals)[]>):
SeedPlan | SeedActuals => seeds[time].reverse()[0];
export const getPlanIdFromTimeAndName = (
  time: string,
  name: string,
  seeds: Dictionary<(SeedPlan | SeedActuals)[]>
): PlanId => {
  const foundPlanId = seeds[time].find((pln) => pln.name === name);
  if (!foundPlanId || !foundPlanId.planId) {
    throw new Error('Looked for a PlanId but couldnt find one. This shouldnt happen.');
  }
  return foundPlanId.planId;
};
export const seedToDropdownItemProps = (seed: SeedPlan | SeedActuals): StringStrictDropdownItemProps => {
  return seed.seedType === SCOPETYPE_ACTUALS ?
    { text: startCase(seed.name), value: seed.name } :
    { text: seed.name, value: seed.name };
};

const ReseedPlanModal = (props: ReseedPlanModalProps) => {
  const {
    scope,
    loading,
    seedOptions,
    initializedPlans,
    onCancel,
    onOpen,
    onSubmit,
    seedAvailableScope: dispatchedSeedAvailableScope,
    labelDimenion,
    anchor
  } = props;

  const [mutationPending, setMutationPending] = useState(false);

  const [currentSeedPlanId, setCurrentSeedPlanId] = useState<PlanId | null>(initializedPlans ? initializedPlans[0].id : null);
  const handleChangeSeedPlanSelections = useCallback((newMembers: TopMembers) => {
    if (!initializedPlans) { return; }
    const newPlanId = planFromSpace(initializedPlans, newMembers).id;
    setCurrentSeedPlanId(newPlanId);
  }, [initializedPlans]);


  const maybeFirstInitPlan = head(initializedPlans) ? head(initializedPlans) : undefined;
  const maybeFirstSeedOption = maybeFirstInitPlan && seedOptions && !isEmpty(seedOptions) ?
    last(seedOptions[Number(maybeFirstInitPlan.id)]) :
    undefined;
  const [
    selectedSeedTime,
    setSelectedSeedTime
  ] = useState(maybeFirstSeedOption ? maybeFirstSeedOption.seedTime : undefined);
  const [
    selectedSeedVersion,
    setSelectedSeedVersion
  ] = useState(maybeFirstSeedOption ? maybeFirstSeedOption.seedType === SCOPETYPE_ACTUALS ? SCOPETYPE_ACTUALS : maybeFirstSeedOption.name : undefined);
  useEffect(() => {
    setSelectedSeedTime(maybeFirstSeedOption ? maybeFirstSeedOption.seedTime : undefined);
    setSelectedSeedVersion(maybeFirstSeedOption ? maybeFirstSeedOption.seedType === SCOPETYPE_ACTUALS ? SCOPETYPE_ACTUALS : maybeFirstSeedOption.name : undefined);
  }, [maybeFirstSeedOption, seedOptions, currentSeedPlanId]);

  const handleSubmit = useCallback(async () => {
    if (selectedSeedTime && selectedSeedVersion && seedOptions && currentSeedPlanId) {
      const maybeSeedPlanId = seedOptions[currentSeedPlanId].find((s) => s.seedTime === selectedSeedTime && s.name === selectedSeedVersion)!.planId;
      const newSeed: SeedActuals | SeedPlan = selectedSeedVersion === SCOPETYPE_ACTUALS && selectedSeedTime && !maybeSeedPlanId ?
        {
          seedTime: selectedSeedTime,
          seedType: SCOPETYPE_ACTUALS,
          name: SCOPETYPE_ACTUALS,
          planId: null,
          applyTo: currentSeedPlanId
        } : {
          seedTime: selectedSeedTime,
          seedType: SCOPETYPE_PLAN,
          name: selectedSeedVersion,
          planId: maybeSeedPlanId!,
          applyTo: currentSeedPlanId
        };
      setMutationPending(true);
      const maybeSeed = await dispatchedSeedAvailableScope(newSeed);
      if (maybeSeed.type === seedAvailableScope.rejected.type) {
        // failure, toast and don't go forward
        toast.error('An error occured seeding your scope');
      } else {
        onSubmit();
      }
    }

    setMutationPending(false);
  }, [currentSeedPlanId, dispatchedSeedAvailableScope, onSubmit, seedOptions, selectedSeedTime, selectedSeedVersion]);
  const handleEnterPress = useHandleKeyPress(handleSubmit);

  const timeVersionMap = useMemo(() => {
    return (seedOptions && currentSeedPlanId) ? groupBy(seedOptions[Number(currentSeedPlanId)], 'seedTime') : {};
  }, [currentSeedPlanId, seedOptions]);
  const versionOptionDropdowns = useMemo(() => {
    return selectedSeedTime && timeVersionMap[selectedSeedTime] ? timeVersionMap[selectedSeedTime].map(seedToDropdownItemProps) : [];
  }, [selectedSeedTime, timeVersionMap]);
  const seedTimeDropdowns = uniqBy(seedOptions![Number(currentSeedPlanId)], 'seedTime').map(t => {
    return { text: t.seedTime, value: t.seedTime };
  });

  return (
    <React.Fragment>
      <div className="initialize-plan">
        <Grid columns={1} doubling={true} stretched={true}>
          <Grid.Column>
            <Segment>
              <Header as="h3" className="initialize-plan-header">
                Overwrite your WP with the selected version
              </Header>
              <p>
                This will erase your current plan values (though you are able use
                &quot;Undo&quot; to return your previous values)
              </p>
              <div>
                {scope && scope.currentAnchors ?
                  <AnchorRadioSelect
                    labelDimenion={labelDimenion!}
                    anchor={anchor!}
                    onUpdateAnchorSelections={handleChangeSeedPlanSelections}
                  />
                  : null}
              </div>
              <div className="dropdown-group">
                <div className="dropdown-group-label">
                  Select the Seed basis Plan Period
                </div>
                <Dropdown
                  fluid={true}
                  loading={loading}
                  disabled={!selectedSeedVersion}
                  scrolling={true}
                  data-qa="reseed-period-dropdown"
                  icon={<i className="chevron far fa-chevron-down icon" />}
                  options={seedTimeDropdowns}
                  value={selectedSeedTime}
                  onChange={(_e, data) => {
                    const newTime = data.value;
                    if (newTime && typeof newTime === 'string' && newTime !== selectedSeedTime) {
                      setSelectedSeedTime(getDefaultOptionFromTime(newTime, timeVersionMap).seedTime);
                    }
                  }}
                />
              </div>
              <div className="dropdown-group">
                <div className="dropdown-group-label">
                  Select the Seed Basis Plan Version
                </div>
                <Dropdown
                  fluid={true}
                  loading={loading}
                  disabled={!selectedSeedVersion}
                  icon={<i className="chevron far fa-chevron-down icon" />}
                  options={versionOptionDropdowns}
                  value={selectedSeedVersion}
                  onChange={(_e, data) => {
                    const newVersion = data.value;
                    if (newVersion === SCOPETYPE_ACTUALS || newVersion === 'plan') {
                      setSelectedSeedVersion(SCOPETYPE_ACTUALS);
                    } else {
                      setSelectedSeedVersion(data.value as string);
                    }
                  }}
                />
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
      <Modal.Footer>
        <Button content="Cancel" onClick={onCancel} />
        <Button
          content="Submit"
          className="import-version-modal-button"
          data-qa="reseed-reseed-btn-reseed"
          onClick={handleSubmit}
          loading={mutationPending || loading}
          onKeyPress={handleEnterPress}
        />
      </Modal.Footer>
    </React.Fragment>
  );
};
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ReseedPlanModal);
