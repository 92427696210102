import { ofType } from 'redux-observable';
import { concatMap, filter, throttleTime, mergeMap, catchError, map } from 'rxjs/operators';
import { empty, NEVER, of } from 'rxjs/index';
import { Observable } from 'rxjs/internal/Observable';
import { AppEpic } from 'epics';
import { receivedWorkingSets } from 'state/workingSets/workingSets.slice';
import { CONTEXT_READY } from 'state/workingSets/workingSets.types';
import { AnyAction } from '@reduxjs/toolkit';
import { isReady, isBusy, isPending, hasScopeId, maybeGetCurrentScopeTimeId } from './Scope.types';
import {
  receivedEopOptions,
  requestAddPrivateVersion,
  requestRefreshGrid
} from './Scope.slice';
import Scope, { SCOPECREATE_WITH_WP } from 'services/Scope.client';
import { toast } from 'react-toastify';
import { SCOPETYPE_ACTUALS } from 'utils/domain/constants';
import { workflowOrPlanToMap } from './codecs/projections/workflowOrPlanToMap';
import { isWorkflowBalance, Workflows } from './codecs/Workflows';
import { getLastTimeMember, SeedActuals, SeedPlan } from './ScopeManagement.slice';
import { inputIsNotNullOrUndefined } from 'state/ViewConfig/ViewConfig.listener';
import { receivedAvailableScopes } from 'state/ViewConfig/ViewConfig.slice';
import { planToSeedPlan } from 'state/scope/codecs/projections/PlanMetadataToSeedPlan';
import { PlanMetadata } from './codecs/PlanMetadata';
import { findEarliestPlans } from 'components/Scopebar/ScopeUtils';
import { isEmpty, mapValues } from 'lodash';
import { fetchWorkflows } from './scope.actions';

// throttle incoming events that can trigger data refreshes
// as sometimes many events can come in rapid succession from the server
// note that both the grid and the macrosummaries listen for the output 'requestRefreshGrid' here
// so they can both eventually be refreshed from the output action
export const refreshGrid: AppEpic =
  (action$, state$): Observable<AnyAction> => {
    return action$.pipe(
      ofType<ReturnType<typeof receivedWorkingSets>
      >(receivedWorkingSets.type),
      filter((action) => {
        // filter only 'ready' events, because we only want to refresh whent he grid is ready
        const scope = state$.value.scope;
        if (isReady(scope) || isBusy(scope) || isPending(scope)) {
          const currentScopeId = scope.id;

          const newScopeStatus = action.payload
            .filter((s) => s.initParams.type === SCOPECREATE_WITH_WP)
            .find(ws => ws.id === currentScopeId)?.status;
          return newScopeStatus === CONTEXT_READY;
        }
        return false;
      }),
      throttleTime(48, undefined, { // the first action fires, but events that come within three frames are ignored
        leading: true, // take the first one
        trailing: false // dont take the last one
      }),
      concatMap(() => of(requestRefreshGrid()))
    );
  };

export const addPrivateVersion: AppEpic =
  (action$, state$, deps) => {
    return action$.pipe(
      ofType<ReturnType<typeof requestAddPrivateVersion>>(requestAddPrivateVersion.type),
      mergeMap(async (action) => {
        // we take in the requested private version, and on success, re-request workflows
        const scope = state$.value.scope;
        const client = deps.serviceEnv.axios;
        const nameToAdd = action.payload.versionName;

        if (isReady(scope) || isBusy(scope)) {
          // eslint-disable-next-line max-len
          const savePromise = await new Scope(client)[action.payload.smartSave ? 'saveSmartPlanVersion' : 'saveVersion'](scope.id, nameToAdd);
          return savePromise;
        }
        return NEVER;
      }),
      mergeMap(() => of(fetchWorkflows())),
      catchError((err) => {
        // TODO: figure out the real error types here
        const maybeScopeId = hasScopeId(state$.value.scope) ? state$.value.scope.id : 'unknown';
        toast.error('An error has occured while saving the version.', {
          position: toast.POSITION.TOP_LEFT
        });
        deps.serviceEnv.logging.error(
          `An error occured fetching the workflows for scope: ${maybeScopeId}`, err);
        return of(fetchWorkflows());
      })
    );
  };

export const setEopActuals: AppEpic =
  (action$, state$, deps): Observable<AnyAction> => {
    return action$.pipe(
      ofType<
        ReturnType<typeof receivedAvailableScopes>
      >('scope/fetchWorkflows/fulfilled', receivedAvailableScopes.type),
      filter(() => isReady(state$.value.scope)),
      map((action) => {
        const scope = state$.value.scope;
        if (isReady(scope)) {
          const workflowPlans = action.type === 'scope/fetchWorkflows/fulfilled' ?
            (action.payload as unknown as Record<number, Workflows>) :
            scope.workflows;
          const maybeTimeMembers = state$.value.viewConfigSlice.availableMembers?.space.time;

          const plans = [...scope.mainConfig?.initializedPlans, ...scope.mainConfig.uninitializedPlans];
          if (isEmpty(plans) || !maybeTimeMembers) {
            return;
          }
          const balanceOptionsRecord = findEarliestPlans(plans).map((earlyPlan) => {
            const earliestPlanData = workflowPlans[earlyPlan.id];
            const plansOnly = earliestPlanData && !Array.isArray(earliestPlanData) ?
              earliestPlanData.plans :
              earliestPlanData;
            const newBalanceOptions = plansOnly ? plansOnly.filter(isWorkflowBalance)
              .map((pln) => {
                return planToSeedPlan(pln.plan, earlyPlan.id);
              }) : [];
            const maybeCurrentScopeTime = maybeGetCurrentScopeTimeId(scope);
            if (maybeCurrentScopeTime && maybeTimeMembers) {
              const previousTime = getLastTimeMember(maybeTimeMembers, maybeCurrentScopeTime);

              // SPIKE: do we need both init and unint plans here?
              const balanceLyActuals: SeedActuals = {
                seedType: SCOPETYPE_ACTUALS,
                seedTime: previousTime.id,
                name: SCOPETYPE_ACTUALS,
                planId: null,
                applyTo: earlyPlan.id
              };
              return [[Number(earlyPlan.id)], [...newBalanceOptions, balanceLyActuals]];
            }
          });
          const plansMap = new Map(balanceOptionsRecord.map((plan, idx) => [plan![0], plan![1]]));
          return Object.fromEntries(plansMap);
        }
      }),
      filter((opts) => inputIsNotNullOrUndefined(opts) && !isEmpty(opts)),
      mergeMap((newOpts) => of(receivedEopOptions(newOpts)))
    );
  };
