import { ofType } from 'redux-observable';
import { filter, mergeMap, map } from 'rxjs/operators';
import { empty, of } from 'rxjs/index';
import { AppEpic } from 'epics';
import { anchorIdsFromMemberTrees, receivedCreateScope, receivedScope } from 'state/scope/Scope.slice';
import { isReady, isScopeReady } from 'state/scope/Scope.types';
import { setLastScopeCreated } from './UiPose.slice';

export const saveLastCreatedScope: AppEpic =
  (action$, state$) => {
    return action$.pipe(
      ofType(receivedCreateScope.type, receivedScope.type),
      map(() => state$.value.scope),
      filter((newScope) => isReady(newScope)),
      mergeMap((newScope) => {
        const viewState = state$.value.viewConfigSlice;
        if (!viewState.currentPerspective || !isReady(newScope) || !newScope.currentAnchors) { return empty(); }
        return of(setLastScopeCreated([viewState.currentPerspective, newScope.currentAnchors]));
      })
    );
  };