import Modal from '@trendmicro/react-modal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dropdown,
  DropdownProps,
  Ref,
  Segment,
  StrictDropdownItemProps
} from 'semantic-ui-react';
import { AppState } from 'store';
import { isReady } from 'state/scope/Scope.types';
import { requestAddPrivateVersion } from 'state/scope/Scope.slice';
import { workflowOrPlanToMap } from 'state/scope/codecs/projections/workflowOrPlanToMap';
import { planToDropdown } from 'state/scope/codecs/projections/PlanMetadataToDropdown';
import { noop, uniqueId, isEmpty } from 'lodash';
import AnchorRadioSelect from 'components/AnchorRadioSelect/AnchorRadioSelect';
import { TopMembers } from 'services/Scope.client';
import { PlanId } from 'state/scope/codecs/PlanMetadata';
import { planFromSpace } from 'components/Scopebar/ScopeUtils';
import { mapValues } from 'lodash';
import { fetchWorkflows } from 'state/scope/scope.actions';

export type VersionModalBodyComponentProps = ReturnType<typeof mapStateToProps> & typeof dispatchToProps & {
  loading: boolean,
  onCancel?: () => void,
  onSubmit?: (name?: string) => void,
  text?: string,
  smartSave?: boolean // this is used only for smartsave.tsx to bypass the redux save
};

const mapStateToProps = (state: AppState) => {
  const { scope } = state;
  if (!isReady(scope)) {
    return {
      importOptions: undefined
    };
  }
  return {
    // should probably filter this somewhere else
    // SPIKE: does this create a new object each time?
    importOptions: mapValues(scope.importOptions, (v, k) => v.filter((p => p.version === 'save'))),
    initializedPlans: scope.mainConfig.initializedPlans,
    scope
  };
};

const dispatchToProps = {
  requestAddPrivateVersion,
  fetchWorkflows
};

const SaveVersion = (props: VersionModalBodyComponentProps) => {
  const {
    onCancel,
    onSubmit,
    fetchWorkflows,
    importOptions,
    requestAddPrivateVersion,
    text = 'WP',
    smartSave = false,
    initializedPlans,
    scope
  } = props;
  const [selectedVersion, setSelectedVersion] = useState<undefined | number>(undefined);
  const [options, setOptions] = useState<StrictDropdownItemProps[]>([]);
  const ddRef = useRef<HTMLInputElement>(null);
  const loading = !importOptions;

  useEffect(() => {
    // focus the input on open, so the user can start typing immediately
    if (ddRef.current) {
      const input = ddRef.current.querySelector('input');
      input ? input.focus() : noop();
    }
    fetchWorkflows();
  }, [fetchWorkflows]);

  const [currentSavePlanId, setCurrentSavePlanId] = useState<PlanId | null>(initializedPlans ? initializedPlans[0].id : null);
  const handleChangeSaveVersionSelections = useCallback((newMembers: TopMembers) => {
    if (!initializedPlans) { return; }
    const newPlanId = planFromSpace(initializedPlans, newMembers).id;
    setCurrentSavePlanId(newPlanId);
  }, [initializedPlans]);

  const handleSubmit = useCallback((options: StrictDropdownItemProps[], selectedVersion: number | undefined) => {
    if (selectedVersion && onSubmit && currentSavePlanId) {
      const verToSubmit = options.find((o) => o.value === selectedVersion);
      verToSubmit && verToSubmit.text ?
        requestAddPrivateVersion({
          versionName: verToSubmit.text.toString(),
          applyTo: currentSavePlanId,
          smartSave
        }) :
        noop();
      onSubmit(); // this just closes the modal
    }
  }, [currentSavePlanId, onSubmit, requestAddPrivateVersion, smartSave]);
  useEffect(() => {
    setOptions(!isEmpty(importOptions) ?
      importOptions![Number(currentSavePlanId!)].map((pln) => workflowOrPlanToMap(pln, planToDropdown)) :
      []
    );
  }, [currentSavePlanId, importOptions, setOptions]);
  const handleOnSave = useCallback(() => {
    // TODO: modal to confirm if overwriting an existing version?
    handleSubmit(options, selectedVersion);
  }, [handleSubmit, options, selectedVersion]);
  const handleOnAdd = useCallback((e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { value } = data;
    if (typeof value !== 'string' || !importOptions) { return; }

    const newId = parseFloat(uniqueId()); // we use number for a lookup later, so generate a unique one here
    const newOptions = [...options, { text: value, value: newId }];
    setSelectedVersion(newId);
    setOptions(newOptions);
  }, [importOptions, options]);
  const handleOnChange = useCallback((_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (data.value && typeof data.value === 'number') {
      setSelectedVersion(data.value);
    }
  }, [setSelectedVersion]);
  const handleEnterPress = useCallback((evt: React.KeyboardEvent<HTMLElement>, data: DropdownProps) => {
    if (evt.key === 'Enter') {
      handleSubmit(options, selectedVersion);
    }
  }, [handleSubmit, options, selectedVersion]);

  return (
    <React.Fragment>
      <Modal.Body>
        <div data-qa="version-save-container">
          <Segment>
            {/* eslint-disable-next-line max-len */}
            Enter a name to save your {text} as, or select an existing name to overwrite that version with your {text}
          </Segment>
          <Ref innerRef={ddRef}>
            <Dropdown
              fluid={true}
              loading={loading}
              search={true}
              selection={true}
              allowAdditions={true}
              data-qa="save-version-dropdown"
              options={options}
              onAddItem={handleOnAdd}
              onChange={handleOnChange}
              value={selectedVersion}
              onKeyPress={handleEnterPress}
            >
            </Dropdown></Ref>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button content="Cancel" onClick={onCancel} />
        <Button
          content="Save"
          loading={loading}
          className="save-version-modal-button"
          data-qa="version-save-btn-save"
          onClick={handleOnSave}
          onKeyPress={handleEnterPress}
        />
      </Modal.Footer>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, dispatchToProps)(SaveVersion);
