import qs from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  RouteComponentProps,
  withRouter
} from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppState, AppThunkDispatch } from '../../store';
import { appReady } from 'state/ViewConfig/ViewConfig.slice';
import { setScope } from '../../state/scope/scope.actions';
import Header from '../Header/Header';
import Scopebar from '../Scopebar/Scopebar';
import './_MainContainer.scss';
import { ViewConfig, ViewEntry, FacetEntry } from 'state/ViewConfig/ViewConfig.types';
import { ConfigEnv, AxiosEnv, SettingsEnv, LoggingEnv } from 'services';
import { getScopeId } from 'state/scope/Scope.types';
import { RouteBuilder } from 'components/RouteBuilder/RouteBuilder';
import { isEmpty } from 'lodash';

type IContainerProps = RouteComponentProps & IContainerValueProps & MainContainerDispatchProps & IContainerOwnProps;

interface IContainerValueProps {
  appReady: boolean,
  viewConfig?: ViewConfig,
  activeViews?: ViewEntry[],
  facet?: FacetEntry,
  scopeId: string | undefined
}

interface IContainerOwnProps {
  serviceEnv: SettingsEnv & AxiosEnv & ConfigEnv & LoggingEnv
}

type MainContainerDispatchProps = ReturnType<typeof mapDispatchToProps>;

function mapStateToProps(state: AppState): IContainerValueProps {
  // TODO: make this more robust, or move it into an epic
  const appReady = !!state.settings.dimensionLabelProperty && !isEmpty(state.settings.entries);
  return {
    appReady,
    facet: state.viewConfigSlice.facet,
    viewConfig: state.viewConfigSlice.viewConfig,
    activeViews: state.viewConfigSlice.activeViews,
    scopeId: getScopeId(state.scope)
  };
}

export const mapDispatchToProps = (
  dispatch: AppThunkDispatch
) => {
  return {
    setScope: (scopeId: string) => {
      setScope(scopeId);
    },
    onMainMount: () => {
      dispatch(appReady());
    }
  };
};

class MainContainer extends React.Component<IContainerProps> {
  componentDidMount() {
    this.props.onMainMount();
  }

  componentDidUpdate() {
    const search = qs.parse(this.props.location.search);
    if (search.scope && typeof search.scope === 'string' && search.scope !== this.props.scopeId && this.props.scopeId) {
      this.props.setScope(this.props.scopeId);
    }
  }

  public render() {
    const { location } = this.props;

    if (!this.props.viewConfig || Object.keys(this.props.viewConfig.views).length === 0 || !this.props.appReady) {
      return <React.Fragment />;
    }

    // don't show the default scopebar when at perspective select
    const showScopebar =
      location.pathname !== '/' && location.pathname !== '/select' ? (
        <Scopebar serviceEnv={this.props.serviceEnv} />
      ) : null;

    return (
      <React.StrictMode>
        <div className="MainContainer">
          <Header />
          {showScopebar}
          <RouteBuilder  location={location} facetConfig={this.props.viewConfig.facets}/>
          <ToastContainer autoClose={5000} />
        </div>
      </React.StrictMode>
    );
  }
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MainContainer)
);
