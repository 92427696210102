import { ofType } from 'redux-observable';
import { concatMap, filter, throttleTime,catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { AppEpic } from 'epics';
import { of } from 'rxjs/index';
import dayjs from 'dayjs';
import { AnyAction } from '@reduxjs/toolkit';
import {
  createNewNonWorkingScope,
  isNonWorkingSet,
  resetNonWorkingSets,
  setActiveNonWorkingScope
} from './nonWorkingSets.slice';
import Scope from 'services/Scope.client';
import { isBusy, isPending, isReady, isScopeReady } from 'state/scope/Scope.types';
import { receivedWorkingSets, setLastPublishTime } from './workingSets.slice';
import { CONTEXT_READY } from './workingSets.types';
import { requestRefreshGrid } from 'state/scope/Scope.slice';
import { fetchWorkflows } from 'state/scope/scope.actions';

// the component fires the create action
// once that comes in we async load the scope
// and attach the review version
export const setActiveSummaryPlan: AppEpic =
  (action$, state$, deps): Observable<AnyAction | undefined> => {
    return action$.pipe(
      ofType<ReturnType<typeof createNewNonWorkingScope>
      >(createNewNonWorkingScope.type),
      concatMap(async (action) => {
        const scopeClient = new Scope(deps.serviceEnv.axios);
        const currentPersp = state$.value.viewConfigSlice.currentPerspective;
        if (!currentPersp) { return; }
        const newNonWorkingScope = await scopeClient.createScope(action.payload, currentPersp);

        if (isScopeReady(newNonWorkingScope)) {
          // if the scope is ready, go ahead and attach the planid to it
          // TODO: move this into a thunk or otherwise make it interact with state directly
          // instead of just jamming it in here
          // TODO2: make these version dynamic/configable
          scopeClient.attach(newNonWorkingScope.id, action.payload.planId);
          newNonWorkingScope.revisions = [...newNonWorkingScope.revisions, ...[
            {
              hidden: false,
              type: 'SingleVersion' as 'SingleVersion',
              version: 'ty-review-approved'
            }, {
              against: 'ty-rp',
              type: 'VarianceVersion' as 'VarianceVersion',
              varType: 'percentage' as 'percentage',
              version: 'ty-review-approved'
            }
          ]];
          return setActiveNonWorkingScope(newNonWorkingScope);
        }
      }),
      catchError((err) => {
        // TODO: figure out the real error types here or build and error narrower
        const stack = err.stack && typeof err.stack === 'string' ? err.stack : undefined;
        deps.serviceEnv.logging.error('An error occured fetching the view config', stack);
        return of(resetNonWorkingSets());
      })
    );
  };

// this is not DRY with a similar function in scope.listener
// TODO: dry it out
export const refreshNonWorkingSummary: AppEpic =
  (action$, state$): Observable<AnyAction> => {
    return action$.pipe(
      ofType<ReturnType<typeof receivedWorkingSets>
      >(receivedWorkingSets.type),
      filter((action) => {
        // filter only 'ready' events, because we only want to refresh whent he grid is ready
        // remove this || once we split non-plannable sets back out
        const scope = state$.value.nonWorkingSets.activeNonWorkingScope || state$.value.scope;
        if (!scope) { return false; }
        if (isReady(scope) || isBusy(scope) || isPending(scope)) {
          const currentScopeId = scope.id;

          const newScopeStatus = action.payload
            .filter(isNonWorkingSet)
            .find(ws => ws.id === currentScopeId)?.status;
          return newScopeStatus === CONTEXT_READY;
        }
        return false;
      }),
      throttleTime(48, undefined, { // the first action fires, but events that come within three frames are ignored
        leading: true, // take the first one
        trailing: false // dont take the last one
      }),
      concatMap(() => of(requestRefreshGrid()))
    );
  };

// TODO: import this to epics.ts once the SSE works as intended
export const refreshWorkflowsOnLatestPublish: AppEpic =
  (action$, state$) => {
    return action$.pipe(
      ofType<ReturnType<typeof setLastPublishTime>
      >(setLastPublishTime.type),
      filter((action) => {
        // filter only 'ready' events, because we only want to refresh whent he grid is ready
        const maybeLastPublishTime = state$.value.workingSets.lastPublishTime;
        const newPublishTime = dayjs(action.payload);
        const lastPublishTime = maybeLastPublishTime ? dayjs(maybeLastPublishTime) : dayjs('1999');
        return newPublishTime.unix() > lastPublishTime.unix();
      }),
      concatMap(() => of(fetchWorkflows()))
    );
  };
