import { ofType } from 'redux-observable';
import { concatMap, filter, map } from 'rxjs/operators';
import { of } from 'rxjs/index';
import { Observable } from 'rxjs/internal/Observable';
import { receivedCreateScope, receivedScope } from 'state/scope/Scope.slice';

import type { AppEpic } from 'epics';
import type { AppThunk } from 'store';
import { addComment, deleteComment, loadScopeComments } from './Comments.actions';
import { LocationChangeAction, LOCATION_CHANGE } from 'connected-react-router';
import { inputIsNotNullOrUndefined } from 'state/ViewConfig/ViewConfig.listener';
import { resetComments } from './Comments.slice';
import { AnyAction } from '@reduxjs/toolkit';

export const scopeReadyGetComments: AppEpic =
  (action$): Observable<AppThunk> => {
    return action$.pipe(
      ofType(
        receivedScope.type, receivedCreateScope.type, addComment.fulfilled.type, deleteComment.fulfilled.type
      ),
      concatMap(() => {
        return of(loadScopeComments());
      })
    );
  };

export const changeEditableStateOfComments: AppEpic =
  (action$, state$): Observable<AnyAction | AppThunk> => {
    return action$.pipe(
      ofType<LocationChangeAction>(LOCATION_CHANGE),
      map(() => {
        const currentTabId = state$.value.viewConfigSlice.currentTabId;
        const previousTabId = state$.value.viewConfigSlice.previousView?.tabId;
        if (currentTabId && previousTabId !== currentTabId) {
          // if going to a new tab, we need to check if we comments need to be switch or cleared
          // from scope to admin plan
          return currentTabId;
        }
      }),
      filter(inputIsNotNullOrUndefined),
      concatMap((tabId) => {
        // TODO: dont hardcode this magic
        switch (tabId) {
          case 'administration':
            return of(resetComments());
          case 'planning-views':
            return of(loadScopeComments());
          default:
            return of(resetComments());
        }
      })
    );
  };
