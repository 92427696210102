import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FavoritesEntry } from '../../services/Favorites';
import './_Sidebar.scss';
import {
  mapStateToProps,
  mapDispatchToProps,
  SidebarValueProps,
  SidebarDispatchProps,
  SidebarOwnProps
} from './Sidebar.container';
import { SidebarProps } from './Sidebar.container';
import { SidebarContextProvider } from './SidebarContext';
import SidebarToggle from './SidebarToggle';
import SidebarLinks from './SidebarLinks';
import { AppState } from 'store';

import i18nService from 'services/i18n.service';
import { useTranslation } from 'react-i18next';

const Sidebar = (props: SidebarProps) => {
  const { axios, favoritesLoaded, loadFavorites, sidebarExpanded, toggleSidebarExpanded } = props;
  const { t } = useTranslation(undefined, { i18n: i18nService });

  useEffect(() => {
    if (loadFavorites && !favoritesLoaded && axios) {
      loadFavorites(axios);
    }
  }, [axios, favoritesLoaded, loadFavorites]);

  const onFaveRemoveClick = (e: React.MouseEvent<HTMLElement>, fave: FavoritesEntry) => {
    e.preventDefault();
    e.stopPropagation();
    const { removeFavorite, axios } = props;

    if (removeFavorite && axios) {
      removeFavorite(axios, fave.key);
    }
  };

  const currentItem = props.item;
  const { items = [], title = '' } = currentItem ? currentItem : {};
  const { favorites = [], groupId = '', pathname = '', queryString = {}, scopeId = '' } = props;
  const pathPrefix = `/#/${pathname
    .split('/')
    .slice(1, 3)
    .join('/')}/`;
  const scope = groupId !== '' && scopeId !== '' ? `?facet=${groupId}&scope=${scopeId}` : '';

  return (
    <SidebarContextProvider items={items} pathname={pathname} pathPrefix={pathPrefix} scope={scope}>
      <div className={`sidebar ${sidebarExpanded ? 'expanded' : 'collapsed'}`}>
        <nav className="sidebar-nav">
          <h2 className="sidebar-title">{title.toUpperCase()}</h2>
          <hr/>
          <SidebarLinks />

          {favorites && favorites.length > 0 && (
            <ul className="sidebar-section-list favorites">
              <li className="sidebar-item">
                <i className="fa-fw far fa-heart-circle" />
                <span className="sidebar-item-text">{t('Favorites')}</span>
              </li>
              {favorites.map((favorite, idx) => {
                const { key = '' } = favorite;
                const favoritePath = `${pathPrefix}favorite?facet=${groupId}&favoriteId=${key}&scope=${scopeId}`;
                const currentFavoritePath = queryString.favoriteId ? queryString.favoriteId : '';
                const active = key === currentFavoritePath;
                const removeMessage = `Remove ${key} from Favorites`;

                return (
                  <li className="sidebar-link-item" key={idx}>
                    <a
                      aria-current={active}
                      className="sidebar-link favorite"
                      data-qa={`favorite:${key}`}
                      href={favoritePath}
                    >
                      <i className="sidebar-link-icon far fa-heart" />
                      <span className="sidebar-link-text">{key}</span>
                    </a>
                    <button
                      aria-label={removeMessage}
                      className="sidebar-remove-favorite"
                      onClick={e => onFaveRemoveClick(e, favorite)}
                      title={removeMessage}
                      type="button"
                    >
                      X
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </nav>
        <SidebarToggle
          sidebarExpanded={sidebarExpanded}
          setSidebarExpanded={toggleSidebarExpanded}
        />
      </div>
    </SidebarContextProvider>
  );
};

export default connect<SidebarValueProps, SidebarDispatchProps, SidebarOwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
