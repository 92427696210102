import { AppThunk } from 'store';
import { ofType } from 'redux-observable';
import { concatMap, filter, take, tap } from 'rxjs/operators';
import { of } from 'rxjs/index';
import { appReady } from 'state/ViewConfig/ViewConfig.slice';
import { Observable } from 'rxjs/internal/Observable';
import { AppEpic } from 'epics';
import { getSettings } from 'state/settings/Settings.actions';

export const setupSettings: AppEpic =
  (action$, _state$, deps): Observable<AppThunk> => {
    const settingsService = deps.serviceEnv.settings;
    return action$.pipe(
      ofType(appReady.type),
      concatMap(_incoming => {
        const action = getSettings(settingsService);
        return of(action);
      })
    );
  };

export const setLocalization: AppEpic =
  (action$, _state$, deps) => {
    const localizationService = deps.serviceEnv.localization;
    return action$.pipe(
      ofType<ReturnType<typeof getSettings.fulfilled>>(getSettings.fulfilled.type),
      take(1),
      tap(action => {
        // WARNING: here by side effects!
        localizationService.setCulture(action.payload.localization.baseLocale);
      })
    );
  };
