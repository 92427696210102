import { ofType } from 'redux-observable';
import { filter, mergeMap, map } from 'rxjs/operators';
import { NEVER, of } from 'rxjs/index';
import { Observable } from 'rxjs/internal/Observable';
import { AppEpic } from 'epics';
import { isReady, isBusy, maybeGetCurrentScopeTimeId } from './Scope.types';
import {
  forceRefreshWorkflows,
  receivedCreateScope,
  receivedScope,
  receivedSeedOptions
} from './Scope.slice';
import { receivedAvailableScopes } from 'state/ViewConfig/ViewConfig.slice';
import { inputIsNotNullOrUndefined } from 'state/ViewConfig/ViewConfig.listener';
import { SeedActuals } from './ScopeManagement.slice';
import { SCOPETYPE_ACTUALS } from 'utils/domain/constants';
import { AnyAction } from 'redux';
import { PlanMetadata } from './codecs/PlanMetadata';
import { findEarliestPlan } from 'components/Scopebar/ScopeUtils';
import { mapValues } from 'lodash';

export const scopeReadyMapActualOptions: AppEpic =
  (action$, state$): Observable<AnyAction> => {
    return action$.pipe(
      ofType<(ReturnType<typeof receivedScope | typeof receivedAvailableScopes | typeof receivedCreateScope>)>(
        receivedScope.type, receivedAvailableScopes.type, receivedCreateScope.type
      ),
      map((action) => {
        // try and get time from either the incoming action or current state
        // taking availabe scopes actions as priority
        return receivedAvailableScopes.match(action) ?
          action.payload.space.time :
          state$.value.viewConfigSlice.availableMembers?.space.time;
      }),
      // fitler if, for whatever reason, we can't find it
      filter(inputIsNotNullOrUndefined),
      // @ts-ignore
      mergeMap((time) => {
        const scope = state$.value.scope;
        if (isReady(scope)) {
          const plans = [
            ...scope.mainConfig.initializedPlans,
            ...scope.mainConfig.uninitializedPlans
          ];
          const plansMap = new Map(plans.map((p) => [p.id, p]));

          const seedActualOptions = mapValues(Object.fromEntries(plansMap), (plan) => {
            const acts: SeedActuals[] = time
              .filter((t) => t.id < plan.space.time)
              .reverse()
              .map((time) => {
                return {
                  seedTime: time.id,
                  seedType: SCOPETYPE_ACTUALS,
                  name: SCOPETYPE_ACTUALS,
                  planId: null,
                  applyTo: plan.id
                };
              });
            return acts;
          }) as Record<number, SeedActuals[]>;
          return of(receivedSeedOptions(seedActualOptions));
        }
        return NEVER;
      })
    );
  };
