import { AxiosInstance } from 'axios';
import { AbstractInMemoryCrud } from './crud/AbstractInMemoryCrud';
import { ICrudEntry } from './crud/ICrudInterface';
import { CONFIG_BASE } from './Config.service';
import { Space } from 'space';
import { DimensionLabelSpace } from 'state/settings/settings.slice';

export interface SettingsEntry extends ICrudEntry {
  value: string,
  type?: string
}

export interface SettingsByKey {
  [key: string]: SettingsEntry
}

export interface SettingsPayload {
  entries: SettingsEntry[]
}
export interface AncillaryPayload extends SettingsPayload {
  dimensionLabelProperty: DimensionLabelSpace
}

export interface LocalizationPayload {
  baseLocale: string
}

export type OverlayVersion = {
  id: string,
  name: string,
  metrics: string[]
}

export type AdvisoryStyle = {
  id: string,
  type: 'outline' | 'hatch',
  color: string
}

export class Settings extends AbstractInMemoryCrud<SettingsEntry> {
  protected client: AxiosInstance;

  constructor(client: AxiosInstance) {
    super();
    this.client = client;
  }

  public getSettings(): Promise<SettingsEntry[]> {
    return this.client
      .get<SettingsPayload>(`${CONFIG_BASE}/settings`)
      .then(response => response.data)
      .then(data => data.entries)
      .then(entries => this.load(entries))
      .then(() => this.entries.slice(0));
  }

  public getAncillaryConfig(): Promise<AncillaryPayload> {
    // this doesn't use the AbstractInMemoryCrud interface
    return this.client
      .get<AncillaryPayload>(`${CONFIG_BASE}/ancillary`)
      .then(response => {
        return response.data;
      })
      .then(data => data);
  }

  public getLocalizationConfig() {
    // this doesn't use the AbstractInMemoryCrud interface
    return this.client
      .get<LocalizationPayload>(`${CONFIG_BASE}/localization`)
      .then(response => {
        return response.data;
      });
  }

  public getOverlayVersions() {
    return this.client
      .get<OverlayVersion[]>(`${CONFIG_BASE}/system-versions`)
      .then(response => {
        return response.data;
      });
  }

  public getAdvisoryConfig() {
    return this.client
      // there's some extra keys on this file we don't need in the frontend, so don't pass them through
      .get<{advisoryStyling:AdvisoryStyle[]}>(`${CONFIG_BASE}/advisories`)
      .then(response => {
        return response.data.advisoryStyling;
      });
  }
}

export default Settings;
